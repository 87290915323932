import axios from "axios";

export class AxiosConfig {
	private static onServerException;
	private static longRunningRequestCurrentCount = 0;
	private static preventConcurrentRequests;

	public static GlobalInit(baseUrl: string, onServerException: (message: string) => void) {
		this.onServerException = onServerException;
		axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
		axios.defaults.headers.common["Pragma"] = "no-cache";
		axios.defaults.baseURL = baseUrl;

		axios.interceptors.response.use(undefined, AxiosConfig.HandleResponseErrors);
	}

	private static cleanUp(config) {
		if (config.LongRunningTimerId) {
			//request finished before the timeout
			window.clearTimeout(config.LongRunningTimerId);
		}
		if (config.completeCallback) {
			//we need to run the complete callback
			config.completeCallback();
			delete config.CompleteCallback;
		}
	}

	//Urls subscribed to this functionality will abort prior pending requests if the same url is requested.
	//Respects subscriptions to ConfigureLongRunningRequest.
	public static PreventConcurrentRequests(urls: string[]) {
		AxiosConfig.preventConcurrentRequests = urls.reduce((ac, a) => ({ ...ac, [a]: null }), {});

		const onRequest = config => {
			if (config.url in AxiosConfig.preventConcurrentRequests) {
				//check if there is one that needs to be invalidated
				if (AxiosConfig.preventConcurrentRequests[config.url]) {
					//abort the old request
					AxiosConfig.preventConcurrentRequests[config.url].controller.abort();
					//Cleanup callback or pending timeout on complete
					AxiosConfig.cleanUp(AxiosConfig.preventConcurrentRequests[config.url].config);
				}
				//set up tracking for this request
				let controller = new AbortController();
				AxiosConfig.preventConcurrentRequests[config.url] = {
					config: config,
					controller: controller
				};
				config.signal = controller.signal;
			}
			return config;
		};
		const onResponse = response => {
			const config = response.config;
			if (AxiosConfig.preventConcurrentRequests[config.url]) {
				AxiosConfig.preventConcurrentRequests[config.url] = null;
			}
			return response;
		};
		axios.interceptors.request.use(onRequest, undefined);
		axios.interceptors.response.use(onResponse, undefined);
	}

	public static ConfigureLongRunningRequest(timeout, callback: (currentCount) => void) {
		const onRequest = config => {
			const onTimeOut = () => {
				callback(++AxiosConfig.longRunningRequestCurrentCount);
				config.completeCallback = () =>
					callback(--AxiosConfig.longRunningRequestCurrentCount);
				delete config.LongRunningTimerId;
			};
			config.LongRunningTimerId = window.setTimeout(onTimeOut, timeout);
			return config;
		};
		axios.interceptors.request.use(onRequest, undefined);

		const onResponse = response => {
			const config = response.config;

			AxiosConfig.cleanUp(config);

			return response;
		};

		const onErrorResponse = error => {
			const config = error.response?.config;
			if (config) {
				AxiosConfig.cleanUp(config);
			}

			return Promise.reject(error);
		};

		axios.interceptors.response.use(onResponse, onErrorResponse);
	}

	private static HandleResponseErrors(error) {
		if (!error.response) {
			//Request was cancelled
			return Promise.reject(error);
		} else {
			let message = error.toString();
			if (error.response) {
				const response = error.response;
				message = "Error from server:\n";
				const status: number = response.status;
				const data = response.data;

				if ([401, 403].includes(status)) {
					window.location.reload(); //reload the page so the user is forced to login
					message = null;
				} else if (status === 404) {
					message += "URL not found.";
				} else if (status >= 500 && data && data.Message) {
					message += data.Message;
				} else {
					message += "Unknown error";
				}
			}

			if (message) {
				AxiosConfig.onServerException(message);
			}
			return Promise.reject(error);
		}
	}
}
